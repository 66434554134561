<template>
    <label v-if="showLabel" @click="getClicked">
      <slot></slot><span v-show="showSign">{{sign}}</span>
    </label>
    <b-input
      class="is-gapless"
      v-else
      v-model="inputValue"
      @keydown.enter.native="inputChanged"
      @blur="inputChanged"
    />
</template>

<script>
export default {
  name: 'label-editable',
  props: {
    'value': {},
    'sign': {
      default: null
    },
    'disabled': {
      default: false
    }
  },
  watch: {
    'value' (value) {
      this.inputValue= this.value
      // this.originalValue = this.value
      if(this.showLabel){
        // this.originalValue = this.inputValue
      }else{
        //Edit mode
        // this.inputValue= this.value
        // let convertField1 = (isNaN(this.originalValue) ? this.originalValue : parseFloat(this.originalValue))
        // let convertField2 = (isNaN(this.inputValue) ? this.inputValue : parseFloat(this.inputValue))
        // if(convertField1 !== convertField2){
        //   this.showSign = true
        // }else{
        //   this.showSign = false
        // }
      }
    }
  },
  computed: {
  },
  methods: {
    getClicked () {
      if(!this.disabled){
        this.showLabel = !this.showLabel
      }
    },
    inputChanged: function(event){
      if(typeof this.originalValue === 'number' && typeof this.inputValue !== 'number') {
        this.inputValue = Number(this.inputValue)
      }
      this.$emit("input", this.inputValue) // https://stackoverflow.com/questions/43701175/is-it-possible-to-change-props-value-from-method-in-vue-component
      this.$emit("after-updated", this.inputValue) //  v-on:after-updated="anyFunction" on the parent dom
      this.$emit("has-updated", {name: this.inputName, newValue: this.inputValue }) //  v-on:has-updated="anyFunction" on the parent dom
      if(!this.showLabel){
        this.showLabel = true
      }  
      let convertField1 = (isNaN(this.originalValue) ? this.originalValue : parseFloat(this.originalValue))
      let convertField2 = (isNaN(this.inputValue) ? this.inputValue : parseFloat(this.inputValue))
      if(convertField2 !== convertField1 && this.sign){
        this.showSign = true
      }else{
        this.showSign = false
      }     
    }
  },
  created () {
    // console.log("***** CREATED ******")
  },
  mounted () {
    // console.log("***** MOUNTED ******")
    // console.log("this.originalValue=" + this.originalValue)
    // console.log("this.value=" + this.value)
    // console.log("this.inputValue=" + this.inputValue)
    // console.log("this.sign=" + this.sign)
    // console.log("this.inputName=" + this.inputName)
  },
  updated () {
    // console.log("***** UPDATED ******")
  },
  destroyed () {
    // console.log("***** DESTROYED ******")
  },
  data () {
    // console.log("***** DATA ******")
    return {
      originalValue: this.value, // v-model entering
      inputValue: this.value,
      showLabel: true,
      showSign: false,
      enterPressed: false
    };
  }
};
</script>
