import { render, staticRenderFns } from "./DatasheetNotes.vue?vue&type=template&id=2ce0a4be"
import script from "./DatasheetNotes.vue?vue&type=script&lang=js"
export * from "./DatasheetNotes.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports